import React from 'react'
import styles from './partnership.module.css'
import alice from '../../assets/images/1231232edws.png'
import icon1 from "../../assets/illustration/web icon 3.png"
import icon2 from "../../assets/illustration/web icon 11.png"
import icon3 from "../../assets/illustration/web icon 7.png"
function Partnership() {
  return (
    <>
        <section className={styles.partnershipSection}>
            <div className={styles.partnershipContainer}>
                <div className={styles.left}>
                    <div className={styles.mainCard}>
                        <h1>Partnership Paradigms</h1>
                        <p>Turning hurdles into milestones, we’re more than just developers</p>
                    </div>
                    <div className={styles.cards}>
                        <img src={icon1} alt="" />
                        {/* <i className="fa-solid fa-dice"></i> */}
                        <div className={styles.cardsWrapper}>
                            <h3>MVP Momentum</h3>
                            <p>Fast-track your ideas with carefully crafted MVPs that capture core functionalities, delivering early value and gathering real user feedback.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        {/* <i className="fa-regular fa-building"></i> */}
                        <img src={icon2} alt="" />
                        <div className={styles.cardsWrapper}>
                            <h3>Enterprise Empowerment</h3>
                            <p>Tailored solutions that meet the unique challenges of your enterprise, fostering growth and ensuring sustainable scalability.</p>
                        </div>
                    </div>
                    <div className={styles.cards}>
                        <img src={icon3} alt="" />
                    {/* <i className="fa-solid fa-user-group"></i> */}
                    <div className={styles.cardsWrapper}>
                        <h3>Dedicated Developer Dream Team</h3>
                        <p>Assemble a team of skilled developers dedicated to understanding your goals and delivering solutions that exceed expectations.</p>
                    </div>
                    </div>
                </div>
                <div className={styles.rigth}>
                    <img src={alice} alt="pic" />
                </div>
            </div>
        </section>
    </>
  )
}

export default Partnership
