import React from 'react'
import styles from './support.module.css';
import tick from '../../assets/illustration/7.png'
import pen from '../../assets/illustration/6.png'
import clock from '../../assets/illustration/5.png'

function Support() {
    return (
        <>
            <section className={styles.supportSection}>
                <div className={styles.supportContainer}>
                    <div className={styles.supportCard}>
                        <img src={tick} alt="" />
                        <h1>Proven Expertise</h1>
                        <p>Our developers are proven experts in developing top-notch, user-friendly & robust applications</p>
                    </div>
                    <div className={styles.supportCard}>
                        <img src={pen} alt="" />
                        <h1>Customization</h1>
                        <p>We make customized applications that are tailored to match your brand’s mission and vision</p>
                    </div>
                    <div className={styles.supportCard}>
                    <img src={clock} alt="" />
                        <h1>Around the clock support</h1>
                        <p>You can count on our 24/7 support service, open communication & timely updates</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Support
