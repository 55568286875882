import React, { useState, useEffect } from 'react';
import logo from "../../assets/images/logo.png";
import styles from "./navbar.module.css";
import { Link } from 'react-scroll';

function Navbar() {
  const [activeLink, setActiveLink] = useState("Home");
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // New state for menu visibility
  const [isSticky, setIsSticky] = useState(false); // New state for sticky navbar

  const handleLinkClick = (link) => {
    setActiveLink(link); // Update active link on click
    setIsMenuOpen(false); // Close the menu after a link is clicked
  };

  const handleMouseEnter = (link) => {
    setHoveredLink(link); // Update hovered link on mouse enter
  };

  const handleMouseLeave = () => {
    setHoveredLink(null); // Reset hovered link on mouse leave
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle menu visibility
  };

  return (
    <div className={`${styles.navbarSection} ${isSticky ? styles.sticky : ''}`}>
     <nav className={styles.navbarContainer}>
      <img src={logo} alt="logo" width={120} />

      {/* Hamburger Button for Mobile */}
      <div className={styles.hamburger} onClick={toggleMenu}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
      </div>

      {/* Navigation Menu */}
      <div className={`${styles.navMenuContainer} ${isMenuOpen ? styles.open : ''}`}>
        <ul className={styles.navMenu}>
          <li
            className={`${styles.navLink} ${activeLink === "Home" ? styles.active : ''} ${hoveredLink === "Home" ? styles.hovered : ''}`}
            onMouseEnter={() => handleMouseEnter("Home")}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to="home"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => handleLinkClick("Home")}
            >
              Home
            </Link>
          </li>
          <li
            className={`${styles.navLink} ${activeLink === "About" ? styles.active : ''} ${hoveredLink === "About" ? styles.hovered : ''}`}
            onMouseEnter={() => handleMouseEnter("About")}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to="business"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => handleLinkClick("business")}
            >
              About
            </Link>
          </li>
          <li
            className={`${styles.navLink} ${activeLink === "Our Services" ? styles.active : ''} ${hoveredLink === "Our Services" ? styles.hovered : ''}`}
            onMouseEnter={() => handleMouseEnter("Our Services")}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to="ourServices"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => handleLinkClick("Our Services")}
            >
              Our Services
            </Link>
          </li>
          <li
            className={`${styles.navLink} ${activeLink === "Blog" ? styles.active : ''} ${hoveredLink === "Blog" ? styles.hovered : ''}`}
            onMouseEnter={() => handleMouseEnter("Blog")}
            onMouseLeave={handleMouseLeave}
          >
            <Link
              to="Blog"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => handleLinkClick("Blog")}
            >
              Portfolio
            </Link>
          </li>
          <li
            className={`${styles.navLink} ${activeLink === "Contact Us" ? styles.active : ''} ${hoveredLink === "Contact Us" ? styles.hovered : ''}`}
            onMouseEnter={() => handleMouseEnter("Contact Us")}
            onMouseLeave={handleMouseLeave}
          >
              Contact Us
          </li>
        </ul>
        <a target='_blank' href='https://calendly.com/auroxatechofficial/software-development-guideline'><button className={styles.navBtn}>Schedule meeting</button></a>
      </div>
    </nav>
   </div>
  );
}

export default Navbar;
