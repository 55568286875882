import React from 'react'
import styles from './hero.module.css'
// import heroGuy from '../../assets/images/insurance-hero-guy-2-188x300.png'
import heroGuy from '../../assets/illustration/hero-girl-with-gradient.png'
import gradient from '../../assets/images/white-gradient.jpg'
import illustration1 from '../../assets/illustration/web pic.png'
import illustration2 from '../../assets/illustration/web pic (1).png'
import illustration3 from '../../assets/illustration/web pic (2).png'
import illustration4 from '../../assets/illustration/web pic (3).png'
// import Animate from 'animate.css-react'
import 'animate.css';
import { useState, useEffect } from 'react'
import { Link } from 'react-scroll' 
function Hero() {
  const [activeLink, setActiveLink] = useState("Home");
  const [hoveredLink, setHoveredLink] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // New state for menu visibility

  const handleLinkClick = (link) => {
    setActiveLink(link); // Update active link on click
    setIsMenuOpen(false); // Close the menu after a link is clicked
  };

    const [animate, setAnimate] = useState(false);

    useEffect(() => {
      // Trigger animation only on first render
      setAnimate(true);
    }, []);

  return (
    <>
       <div className={styles.mainheroSection} >
       <section className={styles.heroSection} id="home">
            <div className={styles.heroContainer}>
                <div className={styles.heroLeft}>
                    <h1>AI-Powered, Complete Digital Solutions for Every Business Need</h1>
                    <p>Receive guidance on digital strategy, direct access to design and development experts, and a platform that’s successfully launched over 100+ apps, websites, and social campaigns globally</p>
                    <div className={styles.heroBtnContainer}>
                    <Link
              to="ourServices"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={() => handleLinkClick("Our Services")}
            > <button className={styles.exploreBtn}>Explore Our Services</button></Link>
                        <a target='_blank' href='https://calendly.com/auroxatechofficial/software-development-guideline'> <button className={styles.startBtn}>Get Started Today</button> </a>
                    </div>
                </div>
                <div className={styles.heroRight}>
                {/* <Animate
    appear="fadeInDown"
    durationAppear={1000}
    component="div" > */}
                    <img src={heroGuy} alt="pic" style={{display: 'block', margin: '0 auto', animationDuration: '0.45s'}}  className="animate__animated animate__fadeInUp" />
                    {/* </Animate> */}
                    {/* <img src={gradient} className={styles.gradientImage} alt="" /> */}
                     <img src={illustration1} className={`${styles.animatedImage}`} alt="" />
              <img src={illustration2} className={`${styles.animatedImage} ${styles.img2} `} alt="" />
              <img src={illustration3} className={`${styles.animatedImage} ${styles.img3} `} alt="" />
              <img src={illustration4} className={`${styles.animatedImage} ${styles.img4} `} alt="" />
                </div>
            </div>
        </section>
       </div>
    </>
  )
}

export default Hero
