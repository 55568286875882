import React from 'react'
import styles from './contact.module.css'
import dp from '../../assets/illustration/cont.png'
import arrow from '../../assets/images/arrow.png'

function Contact() {
  return (
    <>
        <section className={styles.contactSection} id="contactUs">
            <div className={styles.contactContainer}>
                <div className={styles.top}>
                    <h1>Contact us</h1>
                </div>
                <div className={styles.middle}>
                    <img  src={dp} alt="img" />
                </div>
                <div className={styles.bottom}>
                    <div className={styles.left}>
                        <h1>Start your business journey better with Auroxa Tech Consulting</h1>
                        <p>Schedule a 15 minute call to discuss your project and long term goals.</p>
                    </div>
                    <div className={styles.right}>
                        <div className={styles.move}><img src={arrow} alt="img" width={300}/></div>
                        <div><a target='_blank' href='https://calendly.com/auroxatechofficial/software-development-guideline'><button>Schedule a Meeting</button></a></div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Contact
