import React, { useState } from 'react'
import styles from './project.module.css'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import dp1 from '../../assets/images/portfolio1.png';
import dp2 from '../../assets/images/portfolio2.png';
import dp3 from '../../assets/images/portfolio3.png';
import dp4 from '../../assets/images/portfolio4.png';
import dp5 from '../../assets/images/portfolio5.png';
import dp6 from '../../assets/images/portfolio6.png';
import dp7 from '../../assets/images/portfolio7.png';
import dp8 from '../../assets/images/portfolio8.png';
import dp9 from '../../assets/images/portfolio9.png';
import dp10 from '../../assets/images/portfolio10.png';
import dp11 from '../../assets/images/portfolio11.png';
import dp12 from '../../assets/images/portfolio12.png';
import dp13 from '../../assets/images/portfolio13.png';
import dp14 from '../../assets/images/portfolio14.png';


const projects = [
    {
        img: dp1,
        name: 'SOZOKU',
        title: 'A large-scale platform for a Japanese company',
        description: 'Enabling efficient daily management of inquiries, interviews, tax and legal projects, dashboards, and employee, family, and customer management.',
    },
    {
        img: dp2,
        name: 'EDDEKHAR',
        title: 'Comprehensive platform for employee management',
        description: 'Designed to manage employees, salaries, admins, transactions, savings, product financing, and tasks.',
    },
    {
        img: dp3,
        name: 'REVOLUTION CANCER',
        title: 'Groundbreaking website for cancer patients',
        description: 'A website dedicated to providing vital support for cancer patients, representing a major leap forward in delivering comprehensive care, resources, and community outreach.',
    },
    {
        img: dp4,
        name: 'GROWING TO GIVE',
        title: 'Vibrant website for gardening enthusiasts',
        description: 'Inspiring and supporting gardening enthusiasts by offering resources on sustainable gardening practices, community-driven initiatives, and eco-friendly activities.',
    },
    {
        img: dp5,
        name: 'BUCHANS FOR TOWBAR',
        title: 'Top-quality towbar solutions',
        description: 'Specializing in providing high-quality towbars and towing solutions tailored to meet the needs of vehicle owners with expertly engineered products.',
    },
    {
        img: dp6,
        name: 'NUTRI PRO',
        title: 'Wholesome pet nutrition',
        description: 'Enhancing the lives of pets and their owners through wholesome nutrition, crafting recipes that prioritize optimal health and vitality for pets.',
    },
    {
        img: dp7,
        name: 'HBM LIMOS',
        title: 'Luxury limousine services in Australia',
        description: 'Providing stylish and comfortable rides for weddings, corporate events, and airport transfers with high-end vehicles and excellent customer service.',
    },
    {
        img: dp8,
        name: 'CAB BOOKING SOLUTION',
        title: 'Seamless limousine booking experience',
        description: 'Developed by Auroxa Tech, offering users the ability to schedule rides, track their chauffeur, and manage bookings with ease in Australia.',
    },
    {
        img: dp9,
        name: 'FRIDAY APP',
        title: 'Real-time chat application',
        description: 'A chat application featuring socket-based communication, encryption, and full chat functionality, including group chats, voice recordings, and file sharing.',
    },
    {
        img: dp10,
        name: 'ARTBOTIC AI',
        title: 'Creative AI-powered app',
        description: 'A Flutter app leveraging the Stable Diffusion API for creative image generation, customizable settings, and advanced inpainting with subscription options.',
    },
    {
        img: dp11,
        name: 'SEHR APP',
        title: 'Confidential government-level application',
        description: 'A soon-to-launch confidential application in Pakistan, introducing innovative solutions at the government level.',
    },
    {
        img: dp12,
        name: 'SEEZITT',
        title: 'Immersive video-sharing platform',
        description: 'A platform developed in Flutter with TikTok-like functionality, offering advanced video editing tools, social interactions, and content discovery.',
    },
    {
        img: dp13,
        name: 'PAKISTAN ENDOCRINE APP',
        title: 'Event management app for a medical conference',
        description: 'Designed for the 21st Annual Pakistan Endocrine Society event, providing attendees access to the agenda, live updates, streaming, and speaker information.',
    },
    {
        img: dp14,
        name: 'PARKING APPLICATION',
        title: 'Parking management app for a major UAE city',
        description: 'A Flutter-based app that helps users find available parking spots, offering real-time availability information and unique parking codes, with over 50,000+ downloads.',
    }
];

function Project() {
    const [currentSlide, setCurrentSlide] = useState(0);

    // Move to next slide
    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % projects.length);
    };

    // Move to previous slide
    const prevSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + projects.length) % projects.length);
    };
    return (
        <>
        <div className={styles.projectSectionWrapper}>
        <section className={styles.projectSection} id="Blog">
                <div className={styles.projectContainer}>
                    <div className={styles.top}>
                        <h1>Our Portfolio</h1>
                        <p>Explore our cutting-edge solutions from recent endeavors. Each project showcases our commitment to excellence and our ability to transform challenges into success stories.</p>
                    </div>

                    <div className={styles.bottom}>
                        <div className={styles.sliderContainer}>
                            <div className={styles.prevBtn}>
                                <button onClick={prevSlide} style={{ color: "#134084", backgroundColor: "white" }}>
                                    &#10094; {/* Left arrow */}
                                </button>
                            </div>

                            <div className={styles.slides}>
                                {projects.map((project, index) => (
                                    <div
                                        key={index}
                                        className={`${styles.slide} ${index === currentSlide ? styles.activeSlide : ''}`}
                                    >
                                        {index === currentSlide && (
                                            <div className={styles.sliderCards}>
                                                <h3>{project.name}</h3>
                                                <p><strong>{project.title}</strong></p>
                                                <p>{project.description}</p>
                                                <div className={styles.profileContainerSlider}>
                                                    <img src={project.img} alt={project.name} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                            <div className={styles.nextBtn}>
                                <button onClick={nextSlide} style={{ color: "#134084", backgroundColor: "white" }}>
                                    &#10095; {/* Right arrow */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.projectContainer}>
                    <div className={styles.top}>
                        <h1>What Our Cleints Says About Us,</h1>
                    </div>
                    <div className={styles.bottom}>
                        <Carousel
                            showArrows={true}
                            autoPlay={true}
                            infiniteLoop={true}
                            showThumbs={true}
                            showStatus={false}
                            interval={3000}
                            emulateTouch={true}      // Enables swipe on mobile
                            swipeable={true}          // Ensures swipe functionality is enabled
                            dynamicHeight={true}
                        >
                            <div className={styles.cards}>
                                <h3>""Auroxa Tech's development expertise has been a game-changer for our business. Their team truly listens, innovates, and delivers solutions that exceed our expectations"</h3>
                                <p>Auroxa Tech took our project from concept to reality with seamless execution. Their attention to detail, responsiveness, and commitment to quality turned our vision into a product we're incredibly proud of. We genuinely felt like partners throughout the journey, and the results have been fantastic!</p>
                                <div className={styles.profileContainer}>
                                    <i className="fa-solid fa-user"></i>
                                    <div className={styles.info}>
                                        <h3>Alex Chen</h3>
                                        <strong>Product Manager, BrightPath Education</strong>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.cards}>
                                <h3>"The team at Auroxa Tech brought our ideas to life with creativity and precision. Their approach to development is both strategic and customer-focused."</h3>
                                <p>From start to finish, Auroxa Tech’s professionalism and dedication were evident. They guided us through each step of the process, ensuring we were informed and confident in every decision. The final product not only met but exceeded our expectations, and we're excited to continue working with them.</p>
                                <div className={styles.profileContainer}>
                                    <i className="fa-solid fa-user"></i>
                                    <div className={styles.info}>
                                        <h3>Maya Rodriguez</h3>
                                        <strong>CTO, EcoCraft Co.</strong>
                                    </div>
                                </div>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </section>
        </div>
            
        </>
    )
}

export default Project






