import React from 'react'
import styles from './industries.module.css'
import healthCare from '../../assets/images/healthcare.png'
import edu from '../../assets/images/edu.png'
import logictic from '../../assets/images/logictic.png'
import cart from '../../assets/images/cart-1.png'
import fintech from '../../assets/images/fintech.png'
import demand from '../../assets/images/ondemoiad.png'

function Industries() {
  return (
    <>
        <section className={styles.industriesSection}>
            <div className={styles.industriesContainer}>
                <div className={styles.industriesTop}>
                    <h1>Spotlight on Key Industries</h1>
                    <p>By merging cutting-edge technology with industry-specific insights, we deliver apps that not only resonate with end-users but also drive tangible business results</p>
                </div>
                <div className={styles.industriesBottom}>
                    <div className={styles.cards}>
                        <img src={healthCare} alt="img" />
                        <h3>Health Care</h3>
                        <p>Bridging medical professionals and patients with digital precision.</p>
                    </div>
                    <div className={styles.cards}>
                        <img src={edu} alt="img" />
                        <h3>Education</h3>
                        <p>Transforming learning with interactive and accessible solutions</p>
                    </div>
                    <div className={styles.cards}>
                        <img src={logictic} alt="img" />
                        <h3>Logistics</h3>
                        <p>Optimizing deliveries and routes with smart app innovations</p>
                    </div>
                    <div className={styles.cards}>
                        <img src={cart} alt="img" />
                        <h3>E Commerce</h3>
                        <p>Enhancing shopping experiences with intuitive digital platforms</p>
                    </div>
                    <div className={styles.cards}>
                        <img src={fintech} alt="img" />
                        <h3>Fintech</h3>
                        <p>Smoothing transactions in a digital age with secure fintech apps</p>
                    </div>
                    <div className={styles.cards}>
                        <img src={demand} alt="img" />
                        <h3>On Demand Service</h3>
                        <p>Bringing instant services to your fingertips with responsive apps</p>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Industries
