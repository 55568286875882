import React from 'react'
import styles from './footer.module.css'
import call from '../../assets/icons/phone_number_1b2685b22a.svg'
import email from '../../assets/icons/email_us_f495ef2093.png'
import r1 from '../../assets/icons/Group_1000005708_c162021cee.svg'
import r2 from '../../assets/icons/Group_1000005709_6f4a5abefb.svg'
import r3 from '../../assets/icons/Group_1000005710_930415671c.svg'
import r4 from '../../assets/icons/Group_1000005711_d7a4bce3b5.svg'
import insta from '../../assets/icons/instagram_459d2e6756.svg'
import linkedin from '../../assets/icons/linked_in_1e48b7cbdb.svg'
import twitter from '../../assets/icons/twitter_d51ffbc4e5.svg'
import yt from '../../assets/icons/youtube_660c923e48.svg'
import usa from '../../assets/icons/USA_a4cd0947fc.svg'
import canada from '../../assets/icons/canada_861bcce43c.svg'
import china from '../../assets/icons/malasiya_f922b0b1ac.svg'

function Footer() {
  return (
    <>
   <div className={styles.footerMainWrapper}>
   <section className={styles.footerSection} id="About">
        <div className={styles.footerContainer}>
          <div className={styles.left}>
            <section className={styles.iconSection}>
              <div className={styles.iconContainer}>
                <img src={call} alt="img" />
                <div className={styles.iconInfo}>
                  <p>Direct Call</p>
                  <p><a href="tel:+923345815277">+92 334 5815277</a></p>
                  <p><a href="tel:+923035699010">+92 303 5699010</a></p>
                </div>
              </div>
              <div className={styles.iconContainer}>
                <img src={email} alt="img" />
                <div className={styles.iconInfo}>
                  <p>Email us</p>
                  <p><a href="mailto:auroxatechofficial@gmail.com">auroxatechofficial@gmail.com</a></p>
                </div>
              </div>
            </section>
            <section className={styles.reviewSection}>
              <div className={styles.reviewContainer}>
                <i className="fa-solid fa-star"></i>
                <p>5 Star Reviews</p>
              </div>
              <div className={styles.reviewIconContainer}>
                <img src={r1} alt="img" />
                <img src={r2} alt="img" />
                <img src={r3} alt="img" />
                <img src={r4} alt="img" />
              </div>
            </section>
            <section className={styles.reviewSection2}>
              <div className={styles.reviewContainer2}>
                <p>Our Socials</p>
              </div>
              <div className={styles.reviewIconContainer2}>
                <a href="https://www.instagram.com/auroxatechofficial" target='__blank'><img src={insta} alt="img" /></a>
                <a href="https://www.linkedin.com/company/auroxa-tech/posts/?feedView=all" target='__blank'><img src={linkedin} alt="img" /></a>
                <a href="https://x.com/AuroxaTech" target='__blank'><img src={twitter} alt="img" width={36} /></a>
                <a href="https://www.youtube.com/@AuroxaTech" target='__blank'><img src={yt} alt="img" /></a>
              </div>
            </section>
          </div>
          <div className={styles.right}>
            <div className={styles.sectionContent}>
              <h1>Our Services</h1>
              <p>Web App Developer</p>
              <p>Mobile App Developer</p>
              <p>UI/UX Design</p>
              <p>Custom Software Solutions</p>
              <p>API Integration</p>
              <p>E-commerce Development</p>
              <p>Cloud Computing Services</p>
            </div>

            <div className={styles.sectionContent}>
              <h1>Hire Developers</h1>
              <p>Hire Skilled Developers</p>
              <p>Hire Graphic Designers</p>
              <p>Hire Project Managers</p>
              <p>Hire QA Specialists</p>
              <p>Hire DevOps Engineers</p>
              <p>Hire Marketing Strategists</p>
              <p>Hire Content Creators</p>
            </div>

            <div className={styles.sectionContent}>
              <h1>Industry</h1>
              <p>Technology Industry</p>
              <p>Healthcare Industry</p>
              <p>Finance Industry</p>
              <p>Retail Industry</p>
              <p>Education Industry</p>
              <p>Manufacturing Industry</p>
              <p>Automotive Industry</p>
            </div>

            <div className={styles.sectionContent}>
              <h1>Company</h1>
              <p>Innovative Tech</p>
              <p>Your Success</p>
              <p>Quality Commitment</p>
              <p>Global Trust</p>
              <p>Expertise</p>
              <p>Passion & Excellence</p>
              <p>Future Technology</p>

            </div>
          </div>
        </div>
        {/* <div className={styles.flag}>
          <div className={styles.flagContainer}>
            <img src={usa} alt="img" />
            <p>1234 Elm Street</p>
          </div>
          <div className={styles.flagContainer}>
            <img src={canada} alt="img" />
            <p>Washington, D.C. 20009</p>
          </div>
          <div className={styles.flagContainer}>
            <img src={china} alt="img" />
            <p>United States</p>
          </div>
        </div> */}
        <div className={styles.copyrightContainer}>
          <p>© 2024 Auroxa Tech. All rights reserved.</p>
        </div>
      </section>
   </div>
    </>
  )
}

export default Footer
